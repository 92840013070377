/*General definition for mobile*/
/*Backgrounds*/
:root {
  --color-dark: #212529;
  --color-pink: #EE7AD8;
  --color-yellow: 240, 193, 54;
  --color-teal: #90E4CD;
  --color-light-gray: #c5c5c5;
  --color-white: #FFFFFF;
}
body {
  color: var(--color-dark); 
}
.lastCall2Action-background{
  background-color: var(--color-pink);
}
.black-background{
  background-color: var(--color-dark);
}
/*paddings and limits and sections*/
.padding-section{
  padding-top: 32px;
  padding-bottom: 32px;
}
.social-proof-text-section{
  max-width: 175px;
}
/*Texts*/
.title-big{
  font-size: 30px;
  font-weight:800;
  letter-spacing: -1px;
  line-height: 32px;
  white-space: pre-line;
}
.title{
  font-size: 22px;
  font-weight:bold;
  letter-spacing: -1px;
  line-height: 28px;
  white-space: pre-line;
}
.title-attention{
 background-color: var(--color-pink);
 color:var(--color-white);
 padding-left: 5px;
 padding-right: 5px;
 clip-path: polygon(3% 0%, 100% 8%, 97% 100%, 0% 93%);
 white-space: pre-line;
}
.subtitle{
  font-size:16px;
  line-height: 20px;
  letter-spacing: 0px;
  white-space: pre-line;
}
.text{
  font-size:16px;
  line-height: 20px;
  letter-spacing: 0px;
  white-space: pre-line;
}
.text-under-button{
  margin-top: 12px;
  font-size:12px;
  line-height: 12px;
  letter-spacing: 0px;
  white-space: pre-line;
}
.social-proof-text{
  font-size:12px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 12px;
  white-space: pre-line;
}
.social-proof-quote{
  font-size:18px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 24px;
}
.social-proof-name{
  font-size:16px;
  letter-spacing: 0px;
  line-height: 24px;
  white-space: pre-line;
}
.social-proof-badge{
  font-size:16px;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 24px;
}
.accordion-header{
  font-size:16px;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 20px;
  white-space: pre-line;
}
.accordion-text{
  font-size:16px;
  letter-spacing: 0px;
  line-height: 20px;
  white-space: pre-line;
}
.title-features{
  font-size:20px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  white-space: pre-line;
}
.title-offcanvas{
  font-size: 30px;
  font-weight:800;
  letter-spacing: -1px;
  line-height: 32px;
  white-space: pre-line;
}

.list-bullet::marker{
    color:var(--color-yellow);
}


/*Buttons*/

.logInButton{
  font-weight: bold;
  background:var(--color-pink);
  border-radius: 8px 4px 16px 8px;
  border:none;
  min-width: 100px;
}
.joinButton{
  font-weight: bold;
  line-height: 28px;
  border-radius: 8px 4px 16px 8px;
  border:none;
  min-width: 100px;
  color:var(--color-white);
  font-weight: bold;
  font-size:22px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  box-shadow: 0px 4.07px 4.07px rgba(0, 0, 0, 0.25);
}
.joinButton-black{
  background-color: var(--color-dark);
}
.joinButton-pink{
  background-color: var(--color-pink);
}
.buttonHoverEffect:hover{
  box-shadow: 
    7px 7px var(--color-yellow);
}

/* img effects*/
.img-shadows{
  box-shadow: 5px 5px 0px var(--color-pink), -3px -3px 0px var(--color-teal) , 0px 7px 4px #00000040;
  border-radius: 10px;
}
.img-phone-hero{
  height: 400px;
  width: auto;
}
.img-big-hero{
  max-height: 450px;
  z-index: -2;
  position: relative;
  left:-30px;
}
.img-social-proof-user-photo{
  height:65px;
  width:65px;
  border-radius: 50%;
  object-fit: cover;
}
/*icons effects*/
.features-icon-black{
  font-size: 40px;
  color:var(--color-teal);
}
.features-icon-white{
  font-size: 40px;
  color:var(--color-pink);
}
/*Effects for comparison slider*/
.comparison-without-box{
  background-color: var(--color-dark);
  box-shadow: 0px 0px 8px var(--color-white);
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  
}
.comparison-with-box{
  background-color: var(--color-white);
  border-radius: 10px;
  
  
}

.comparison-without-title{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}
.comparison-without-text{
  font-size: 16px;
  text-align: start;
}


/*Effects for socialproof slider*/
.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: var(--color-light-gray);
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: var(--color-dark);
}

.arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #f0c136;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
/*Videos for Landing*/
.video{
  width: 100%;
}

/* Definition for full screen */
@media (min-width:1200px) {
  /*paddings and limits and sections**/
  .padding-section{
    padding-top: 80px;
    padding-bottom: 80px;

  }
  .social-proof-text-section{
    max-width: 250px;
  }
  /*Texts*/
  .title-big{
    font-size: 64px;
    font-weight:800;
    letter-spacing: -1.5px;
    line-height: 64px;
  }
  .title{
    font-size: 48px;
    font-weight:bold;
    letter-spacing: -1.5px;
    line-height: 63px;
  }
  .subtitle{
      font-size:20px;
      line-height: 35px;
      letter-spacing: 0px;
  }
  .text{
    font-size:24px;
    line-height: 28px;
    letter-spacing: 0px;
  }
  .text-under-button{
    margin-top: 12px;
    font-size:14px;
    line-height: 20px;
    letter-spacing: 0px;
  }
  .social-proof-text{
    font-size:18px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 22px;
  }
  .social-proof-quote{
    font-size:24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 36px;
  }
  .social-proof-name{
    font-size:18px;
    letter-spacing: 0px;
    line-height: 24px;
  }
  .social-proof-badge{
    font-size:18px;
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 24px;
  }
  .title-offcanvas{
    font-size: 48px;
    font-weight:bold;
    letter-spacing: -1.5px;
    line-height: 63px;
  }
  .accordion-header{
    font-size:20px;
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 30px;
  }
  .accordion-text{
    font-size:20px;
    letter-spacing: 0px;
    line-height: 30px;
  }
  .title-features{
    font-size:24px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 28px;
  }
  /*img effects*/
  .img-phone-hero{
    height: 550px;
    width: auto;
  }
  .img-social-proof-user-photo{
    height:100px;
    width:100px;
  }
  /*icons effects*/
  .features-icon{
    font-size: 45px;
    color:var(--color-teal);
  }
  /*Videos for Landing*/
  .video{
    width: auto;
    max-height: 350px;
  }
  

}
/*AlertTop bar*/
.alert-bar-discount {
  position: fixed;
  top: 50;
  left: 0;
  width: 100%;
  background: #efb810;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  z-index: 99;
  color: var(--color-dark);
}
.alert-bar-discount a {
  color: #C31016;
}

/*CSS to make countdown clock*/
.countdown-container {
  text-align: center;
  color: white;
}

.countdown-box {
  display: inline-block;
  background-color: #3D4044; /* Dark grey background */
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  box-shadow: 0px 4.07px 4.07px rgba(0, 0, 0, 0.25);
  width: 290px;

}
.countdown-alert-bar{
  font-size: 16px;
  max-width: 140px;
  padding: 3px 10px;
}

/* START css to call atention to the signal buttons*/

/* Blinking to call attention*/
@keyframes blink {
  0%,70%, 90% {
    background-color: transparent; /* Start and end with no color */
  }
  80%, 100%{
    background-color: rgba(var(--color-yellow), 0.5); /* Blink with color */
  }
}

.btn-outline-secondary.callAttention {
  position: relative;
}

.btn-outline-secondary.callAttention::after,
.btn-outline-secondary.callAttention::before {
  border-radius: 5px;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  animation:blink 2.5s linear infinite alternate 5s;
}
/* END css to call atention to the signal buttons*/

@media (max-width: 374px) {
  .alert-bar-discount {
    font-size: 16px; 
  }
  .countdown-alert-bar {
    font-size: 14px;
  }
}
