
  .badge-pro{
    font-size: 10px;
    margin: 1px;
    margin-left: 5px;
    background-color: #E0B700 !important; /* Use your custom color */
  }
  
  .badge-premium{
    font-size: 10px;
    margin: 1px;
    margin-left: 5px;
    background-color: #A1A1A1 !important; /* Use your custom color */
  }
  
  