/* Variables for colors and font sizes */
:root {
  --primary-color: #e48fd4;
  --primary-hover-color: #d579b5;
  --background-gradient-start: #ffffff;
  --background-gradient-end: #e8f3f3;
  --header-bg-color: #ffffff;
  --header-text-color: #212529;
  --body-text-color: #212529;
  --border-color: #e48fd4;
  --secondary-bg-color: #d2d3d6;
  --secondary-text-color: #212529;
  --premium-card-bg-color: #e8f3f3;
  
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-large: 18px;
}

/* Main container styles */
.tbl-container {
  width: 100%;
  background: linear-gradient(to right, var(--background-gradient-start), var(--background-gradient-end));
  border-radius: 15px;
  font-size: var(--font-size-small);
  color: var(--body-text-color);
}

@media (min-width: 990px) {
  .tbl-container {
    font-size: var(--font-size-medium);
    
  }  
}

/*Texts*/
.discount-text{
  font-size: 95px;
  font-weight: bold;
  text-shadow:  5px 5px 0px var(--color-pink), -3px -3px 0px var(--color-teal)
}

/* Header styles */
.tbl-header {
  background-color: var(--header-bg-color);
  color: var(--header-text-color);
}

th {
  padding: 8px 40px;
  text-align: left;
  font-weight: 700;
  text-transform: uppercase;
}

/* Table data styles */
td {
  padding: 0.15rem;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  border-top: solid 1px var(--border-color);
}

.region-highlight {
  background-color: var(--background-gradient-end); /* Change to your desired highlight color */

}

/* Ensure hover effect for all rows in the group */
tbody tr:hover {
  background-color: var(--background-gradient-end); /* Row hover effect */
}

/* Button styles */
.plan button {
  font-size: var(--font-size-large);
  background-color: #EE7AD8;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out;
}

.plan button:hover {
  background-color: var(--primary-hover-color);
}

/* Additional text styles */
.plan-text {
  font-weight: 500;
}

.billing-not-selected {
  background-color: var(--secondary-bg-color);
  color: var(--secondary-text-color);
}

.card-premium {
  background-color: var(--premium-card-bg-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  th,
  td {
    font-size: var(--font-size-small);
    padding: 0.2rem;
  }

  .plan button {
    font-size: var(--font-size-medium);
  }
}
@media (max-width: 375px) {/*Texts*/
  .discount-text{
    font-size: 90px;
    font-weight: bold;
    text-shadow:  5px 5px 0px var(--color-pink), -3px -3px 0px var(--color-teal)
  }

}


