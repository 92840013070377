.img-width img {
    width: 100%; /* Set your desired styles for the img element */
  }

/* WikiPages.css */
.katex-display {
    max-width: 100%;
  
    /* Media query for smaller screens (e.g., mobile devices) */
    @media (max-width: 767px) {
      font-size: 10px; /* Adjust the font size for smaller screens */
    }
  }