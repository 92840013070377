/* WikiPages.css */
.table-text {
    font-size: 10px;
    @media (min-width:990px) {
      font-size: 15px; /* Adjust the font size for smaller screens */
    }
  }

  .date-text {
    font-size:8px; 
    @media (min-width:990px) {
      font-size:15px; /* Adjust the font size for smaller screens */
    }
  }

  .separate-50{
    height:50%; /* Adjust the font size for smaller screens */
    @media (min-width:990px) {
      height:50%; /* Adjust the font size for smaller screens */
    }
  }