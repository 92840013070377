/* CustomCarousel.css */
.carousel-control-prev-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23e48fd4%27 %3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e")
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23e48fd4%27 %3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e")
}

.carousel-indicators [data-bs-target]{

  background-color: #e48fd4;

}
.carousel-height{
  height: 18rem;
}
.text-line-carousel{
font-size: 0.8rem;
}

@media (min-width:990px) {
  .text-line-carousel{
    font-size: 1rem;
  }
  .carousel-height{
    height: 17rem;
  }

}