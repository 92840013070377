.notification-top-bar {
    position: fixed;
    top: 50;
    left: 0;
    padding: 1px;
    width: 100%;
    background: #f0c136;
    text-align: center;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 14px;
  }
  .notification-top-bar p {
    padding: 0;
    margin: 0;
  }
  .notification-top-bar p a {
    color: #d601af;
    font-weight: bold;
    
  }
  @media (min-width:990px) {
    .notification-top-bar {

      padding: 5px;

      font-size: 16px;
    }
  }